body.template-blogarchives {
  main > header {
    grid-column: 1 / span 4;
    padding-inline: var(--entry-padding, 0);
    @media (min-width: 48rem) {
      grid-column: 1 / span 4;
      margin: 0 0 5rem;
      padding: 0;
    }
  }
}
