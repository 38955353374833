@property --brand-blue {
  syntax: "<color>";
  inherits: true;
  initial-value: #003d6e;
}

:root {
  --font--mono: 'JetBrains Mono', 'JetBrains Mono NL', monospace;
  --font--sans-serif: 'articulat-cf', 'Helvetica Neue', Arial, sans-serif;
  --brand-blue: #003d6e;
}
