body.template-blogpost {
  > header {
    display: none;
  }
  @media (min-width: 48rem) {
    grid-template-rows: 1fr min-content;
    main {
      grid-row: 1;
    }
    > footer {
      grid-row: 2;
    }
  }
  .header-image {
    position: relative;
    margin: 0 0 0.625rem calc(-1 * var(--entry-padding, 0));
    width: calc(100% + 2 * var(--entry-padding, 0));
    @media (min-width: 48rem) {
      grid-column: 1 / span 2;
      margin: 0 0 3.25rem;
    }
  }
  .header-image figcaption {
    font-size: 0.625rem;
    line-height: 1.38;
  }
  .header-image figcaption p {
    margin: 0;
  }
  .header-image > canvas,
  .header-image img {
    display: block;
    height: 250px;
    object-fit: cover;
    width: 100%;
    @media (min-width: 48rem) {
      height: 340px;
    }
  }
  .header-image > canvas {
    pointer-events: none;
    position: absolute;
    top: 0;
  }

  h1 {
    font-size: clamp(1.75rem, 1.0304rem + 2.9524vw, 3.6875rem)
  }

  h1 + h2 {
    font-size: 1.24rem;
    font-size: 2.25rem;
    margin: 0.75rem 0;
  }
  hgroup {
    padding-inline: var(--entry-padding);
  }

  .h-entry {
    font-size-adjust: 0.52;
    font-size-adjust: from-font;
    grid-gap: 0 100px;
    max-width: 100vw;
    @media (min-width: 48rem) {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 300px;
      max-width: calc(100vw - 220px);
    }
  }
  .h-entry > .sidebar {
    grid-column: 2;
    grid-row: 2 / span 4;
    margin-top: 5rem;
    @media (min-width: 48rem) {
      margin-top: 0;
    }
  }
  .sidebar > aside {
    background: var(--brand-blue);
    background: linear-gradient(to bottom, var(--brand-blue), var(--brand-blue)), url('/theme/checkerboard.svg');
    background-position: left 10px, left top;
    background-repeat: no-repeat, repeat;
    background-size: cover, 4px 4px;
    color: #fff;
    margin-inline: var(--entry-padding);
    padding: 1.375rem 1.25rem 0.75rem;

    > h2 {
      font-size: 1.5rem;
      line-height: calc(33.12 / 24);
    }
    @media (prefers-color-scheme: dark) {
      background: var(--brand-blue);
      background: linear-gradient(to bottom, #fff, #fff), url('/theme/checkerboard.svg');
      color: var(--brand-blue);
    }
  }

  .authors {
    font-size: 1.25rem;
    margin: 0.625rem 0 0.625rem;
  }

  .dt-published {
    font-size: 1.125rem;
    font-weight: 400;
  }
  .dt-published,
  .authors,
  .e-content {
    grid-column: 1;
    padding-inline: var(--entry-padding);
  }
  .e-content {
    font-size: 1.125rem;
    line-height: calc(30 / 18);
    margin-top: 1.875rem;
    overflow: hidden;
    position: relative;
    width: 100%;
    @media (min-width: 48rem) {
      margin: 5rem 0;

      heading-anchors {
        > *:not(h2):first-child {
          font-size: 1.5rem;
          line-height: 1.5;
          margin-top: 0;
        }
      }
    }
  }

  .comments {
    h2 {
      margin-bottom: 1.25rem;
    }
  }

}

