.button {
  background: var(--brand-blue);
  color: #fff;
  display: inline-block;
  font-weight: 700;
  padding: 0.5rem 0.875rem;
  text-decoration: none;
  @media (prefers-color-scheme: dark) {
    background: #fff;
    color: var(--brand-blue);
  }
}
