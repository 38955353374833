html,
body {
  background: #fff;
  color: var(--brand-blue);
  font-family: var(--font--sans-serif);
  letter-spacing: -0.03em;
  @media (prefers-color-scheme: dark) {
    background: var(--brand-blue);
    color: #fff;
  }
}

body > header svg {
  color: var(--brand-blue);
  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
}
a {
  color: currentColor;
}

.site-logo {
  display: block;
  height: 2.5rem;
  position: relative;
  width: calc(34rem / 16);
  > img {
    display: block;
    height: 100%;
    width: 100%;
  }
  @media (min-width: 48rem) {
    margin: 0 0 3.75rem;
  }
}

main img {
  filter: url(#monochrome);
}

body > footer {
  width: 100vw;
  > p {
    padding: 0 0.625rem;
  }
  &::after {
    background-color: var(--brand-blue);
    background: linear-gradient(to bottom, var(--brand-blue), var(--brand-blue)), url('/theme/checkerboard.svg');
    background-position: left 10px, left top;
    background-repeat: no-repeat, repeat;
    background-size: cover, 4px 4px;
    content: '';
    display: block;
    height: 5rem;
    width: 100%;
  }
  @media (min-width: 48rem) {
    > p {
      padding: 0 2rem;
    }
    &::after {
      height: 100%;
    }
  }
}


h1 {
  font-size: calc(58rem / 16);
  font-size: clamp(2.625rem, 2.2536rem + 1.5238vw, 3.625rem);
  font-weight: 400;
  line-height: calc(72 / 58);
  @media (min-width: 48rem) {
    font-weight: 300;
  }
}

h2 {
  font-size: 2.25rem;
  font-size: clamp(1.75rem, 1.425rem + 1.3333vw, 2.625rem);
  font-weight: 400;
  line-height: calc(50 / 36);
}

hr {
  background: transparent;
  border: none;
  border-bottom: solid 1px #fff;
  border-top: solid 1px var(--brand-blue);
  color: currentColor;
  height: 2px;
  margin: 1.25rem 0;
  padding: 0;
}

iframe {
  display: block;
}
img {
  height: auto;
  max-width: 100%;
}

img + h2 {
  margin-top: 1em;
}

blockquote,
figure:has(> blockquote) {
  border-left: solid 2px currentColor;
  margin: 1.875rem 2rem 1.875rem;
  padding-left: 1rem;
  width: fit-content;
}

figure > blockquote {
  border: none;
  margin: 0;
  padding-left: 0;
}

figure > blockquote ~ figcaption {
  text-align: right;
  &::before {
    content: "—";
  }
}

blockquote > p {
  &:first-child { margin-top: 0; }
  &:last-child { margin-bottom: 0; }
}

blockquote + blockquote,
figure + figure {
  margin-top: 3rem;
}

code,
kbd,
samp {
  font-family: var(--font--mono);
}

p > img {
  display: inline;
}
p > kbd {
  white-space: nowrap;
}
code,
samp {
  @extend %code !optional;
}
p code {
  display: inline;
}

ins {
  font-style: italic;
  text-decoration: none;
}

ol,
ul,
p {
  margin: 1.875rem 0;
}
ol ul,
ul ol,
ol ol,
ul ul {
  margin-top: 0;
}

pre {
  max-width: 100%;
  overflow: hidden;
  position: relative;
}
/* The preferred markup pattern for block code examples is <pre><code>Hello World</code></pre>, but some of our legacy content doesn't match that. So we need to account for it */
pre:not(:has(> code)) {
  overflow: auto;
}

pre > code,
pre > kbd,
pre > samp {
  display: block;
  padding: 0.25rem;
}
pre:has(> code),
pre:has(> kbd),
pre:has(> samp) {
  margin: 1.875rem 0;
}

pre > code,
pre > samp {
  max-width: 100%;
  overflow: auto;
}

strong {
  font-weight: 700; /* Fun fact, the user agent value for this is "bolder", not "bold" (at least in Firefox) */
}

button,
input,
select,
textarea {
  border: solid 1px currentColor;
  border-radius: 0;
  font: inherit;
  padding: 0.125rem 0.25rem;
}
button {
  color: currentColor;
}
input,
select,
textarea {
  background: #fff;
  color: var(--brand-blue);
}
textarea {
  resize: vertical;
}

label {
  display: block;
  font: 700 1.125rem / 1.38 var(--font--sans-serif);
}

[type="submit"] {
  background-color: var(--brand-blue);
  background-image: linear-gradient(to top, transparent 0%, transparent 10px, var(--brand-blue) 10px, var(--brand-blue) 100%), url(/theme/checkerboard.svg);
  background-position: left bottom, left bottom;
  background-repeat: no-repeat, repeat;
  background-size: cover, 4px 4px;
  color: #fff;
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 3.75rem 0;
  padding: 0.5rem 1.25rem 1.25rem;
  text-decoration: underline;
  width: fit-content;

  @media (prefers-color-scheme: dark) {
    background-color: #fff;
    background-image: linear-gradient(to top, transparent 0%, transparent 10px, #fff 10px, #fff 100%), url(/theme/checkerboard.svg);
    color: var(--brand-blue);
  }
}
