%code {
  background: linear-gradient(to bottom, #fff, #fff), url('/theme/checkerboard.svg');
  background-color: #fff;
  background-position: 1px 1px, top left;
  background-repeat: no-repeat, repeat;
  background-size: calc(100% - 2px) calc(100% - 3px), 4px 4px;
  display: inline-block;
  line-height: calc(5em / 3 - 3px);
  padding: 0 0.25rem;
  @media (prefers-color-scheme: dark) {
    background-image: linear-gradient(to bottom, var(--brand-blue), var(--brand-blue)), url('/theme/checkerboard.svg');
  }
}

%sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}