.p-comment {
  .comment-meta {
    display: flex;
    font-size: 0.875rem;
    font-weight: 600;
    gap: 0.25rem;
    margin-bottom: 0.5rem;
    .dt-published {
      font-size: inherit;
      font-weight: inherit;
      margin: 0;
    }
  }
  .comment-body > p:first-child {
    margin-top: 0;
  }
}