body.template-contact {
  .header-image {
    position: relative;
    img, canvas {
      height: auto;
      margin: 0 0 2.5rem;
      object-fit: cover;
      width: 100%;
    }
    canvas {
      left: 0;
      position: absolute;
      top: 0;
    }
  }
  h1 {
    font-weight: 700;
    padding: 0 0.625rem;
    @media (min-width: 48rem) {
      padding-inline: 0;
    }
  }
  form {
    font: 400 1.5rem / 1.5 var(--font--sans-serif);
    margin: 2rem 0.625rem;
    max-width: 55rem;
    @media (min-width: 48rem) {
      margin: 2rem 2rem 2rem 0;
    }
    @media (min-width: 64rem) {
      display: grid;
      grid-gap: 1.25rem;
      grid-template-columns: 1fr 1fr;
      margin-block-start: 0.875rem;
      > p {
        grid-column: 1 / span 2;
        &:first-child {
          margin-top: 0;
        }
      }

      .field {
        grid-column-start: unset;
        grid-column-end: span 1;
        margin: 0;
      }

      .field--wide {
        grid-column: 1 / span 2;
      }
    }
  }
}