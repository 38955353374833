@media screen {
  body {
    align-items: start;
    display: flex;
    flex-direction: column;
  }
  .branding {
    display: none;
  }
}

@media screen and (min-width: 48rem) {
  body {
    --grid-gap: 1.25rem;
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: 200px 1fr;
    grid-template-rows: 120px 1fr min-content;
    min-height: 100dvh;
  }
  body > header {
    align-items: center;
    display: flex;
    grid-column: 2;
    grid-row: 1;
    height: 120px;
    justify-content: start;
  }
  body > footer {
    display: grid;
    grid-column: 1 / span 2;
    grid-template-columns: subgrid;
    grid-row: 3
  }

  body > nav {
    grid-column: 1;
    grid-row: 1 / span 2;
    padding-block: 2.5rem;
    padding-inline-start: 1.875rem;
  }

  main {
    align-items: start;
    grid-column: 2;
    grid-row: 2;
  }

  .branding {
    display: block;
    font-size: 1.125rem;
    line-height: 1;
    padding: 0;
  }
}

