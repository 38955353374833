.hidden,
[hidden] {
  display: none !important;
}

.sr-only:not(:focus) {
  @extend %sr-only;
}

:root:not(.js) .js-only {
  display: none !important;
}

/* stylelint-disable -- this is only for debugging */
a[href="#FIXME"] {
  text-decoration-color: var(--error--500);
  text-decoration-line: underline !important;
  text-decoration-style: wavy !important;
  text-decoration-thickness: 0.09375rem;
}
/* stylelint-enable */


.no-filter {
  filter: none !important;
}