.tag-list {
  list-style: '';
  margin: 0.875rem 0 0;
  padding: 0;

  > li {
    @extend %code !optional;
    color: var(--brand-blue);
    display: block;
    font: 700 1rem / calc(21.12 / 16) var(--font--mono, monospace);
    padding: 0.25rem 0.3125rem;
    width: fit-content;
    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }
  > li + li {
    margin-top: 0.625rem;
  }
}
