body.section-blog {
  --entry-padding: 0.625rem;
  @media (min-width: 48rem) {
    --entry-padding: 0;
  }

  .h-feed {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.875rem 0.625rem;
    @media (min-width: 48rem) {
      display: grid;
      grid-gap: 5rem 1.875rem;
      grid-template-columns: repeat(4, 1fr);
      margin-block-end: 5rem;
    }
  }
  .blog-photo-link {
    display: block;
    aspect-ratio: 30 / 17;
    left: calc( -1 * var(--entry-padding, 0));
    overflow: hidden;
    position: relative;
    width: calc(100% + 2 * var(--entry-padding, 0));

    > canvas,
    > picture,
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    > canvas {
      position: absolute;
      top: 0;
    }
  }

  .h-entry {
    padding-inline: var(--entry-padding);
    position: relative;
    &.h-entry--wide {
      grid-column-end: span 2;
    }
    @media (min-width: 48rem) {
      grid-column-end: span 2;
    }
    @media (min-width: 64rem) {
      grid-column-end: span 1;
    }
  }

  .dt-published {
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: calc(19.32 / 14);
    margin-bottom: 0.3125rem;
    > .time {
      display: none;
    }
    @media (min-width: 48rem) {
      > time {
        display: initial;
      }
    }
  }

  .post-title {
    font-size: 1.5rem;
    font-size: clamp(1.25rem, 1.1571rem + 0.381vw, 1.5rem);
    margin-top: 0.625rem;
    word-break: break-word;
    > a {
      text-decoration: none;
    }
  }

  .h-entry--wide .post-title {
    font-size: 2.25rem;
    font-size: clamp(1.75rem, 1.425rem + 1.3333vw, 2.625rem);
  }

  .h-entry--wide .p-author {
    font-size: clamp(1.125rem, 1.2964rem + -0.1905vw, 1.25rem);
    margin: 0.3125rem 0 0.625rem;
  }

  .h-entry--wide .p-summary {
    > p:first-child {
      margin-top: 0;
    }
    > p:last-child {
      margin-bottom: 0;
    }
  }

}