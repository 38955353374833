body.template-blog {
  main > header {
    grid-column: 1 / span 2;
    margin: 0 0 2.25rem;
    padding-inline: var(--entry-padding, 0);
    @media (min-width: 48rem) {
      grid-column: 1 / span 4;
      margin: 0 0 5rem;
      padding: 0;
    }
  }

  .p-author {
    margin: 0.625rem 0;
  }
}
