.footnotes {
  border-top: solid 1px currentColor;
}

hr + .footnotes {
  border-top: none;
}

.footnotes > ol {
  counter-reset: footnotes;
  display: grid;
  grid-gap: 1.875rem 0.5rem;
  grid-template-columns: min-content 1fr;
  padding: 0;
}

.footnote-item {
  counter-increment: footnotes;
  display: grid;
  grid-column: 1 / span 2;
  grid-template-columns: subgrid;
  list-style: none;
  &::before {
    content: counter(footnotes) ". ";
    font-size: 0.75em;
    vertical-align: super;
  }

  > p:first-of-type {
    margin-top: 0;
  }
  > p:last-child {
    margin-bottom: 0;
  }
}
