side-nav {
  --nav-arrow: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23003d6e" viewBox="0 0 13 13"><path d="M0 1h1V0H0z"/><path d="M1 2h1V1H1z"/><path d="M2 3h1V2H2z"/><path d="M3 4h1V3H3z"/><path d="M4 5h1V4H4z"/><path d="M5 6h1V5H5z"/><path d="M6 7h1V6H6z"/><path d="M7 8h1V7H7z"/><path d="M8 9h1V8H8z"/><path d="M10 11h1v-1h-1z"/><path d="M9 10h1V9H9z"/><path d="M11 12h1v-1h-1z"/><path d="M12 13h1v-1h-1z"/><path d="M11 13h1v-1h-1z"/><path d="M10 13h1v-1h-1z"/><path d="M9 13h1v-1H9z"/><path d="M8 13h1v-1H8z"/><path d="M12 12h1v-1h-1z"/><path d="M12 11h1v-1h-1z"/><path d="M12 10h1V9h-1z"/><path d="M12 9h1V8h-1z"/></svg>');
  align-self: start;
  display: grid;
  font-size: 2.625rem;
  font-weight: 300;
  grid-gap: 1.5rem 1.25rem;
  grid-template-columns: 34px 1fr 34px;
  line-height: calc(57.96 / 42);
  padding: 3.75rem 0.625rem 1.25rem;
  width: 100%;

  @media (min-width: 48rem) {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: calc(24.28 / 18);
    position: sticky;
    top: 0;
  }
  @media (prefers-color-scheme: dark) {
   --nav-arrow: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23fff" viewBox="0 0 13 13"><path d="M0 1h1V0H0z"/><path d="M1 2h1V1H1z"/><path d="M2 3h1V2H2z"/><path d="M3 4h1V3H3z"/><path d="M4 5h1V4H4z"/><path d="M5 6h1V5H5z"/><path d="M6 7h1V6H6z"/><path d="M7 8h1V7H7z"/><path d="M8 9h1V8H8z"/><path d="M10 11h1v-1h-1z"/><path d="M9 10h1V9H9z"/><path d="M11 12h1v-1h-1z"/><path d="M12 13h1v-1h-1z"/><path d="M11 13h1v-1h-1z"/><path d="M10 13h1v-1h-1z"/><path d="M9 13h1v-1H9z"/><path d="M8 13h1v-1H8z"/><path d="M12 12h1v-1h-1z"/><path d="M12 11h1v-1h-1z"/><path d="M12 10h1V9h-1z"/><path d="M12 9h1V8h-1z"/></svg>');
  }
}

side-nav .short-title {
  align-self: end;
  background: var(--nav-arrow) no-repeat left center / calc(13rem / 16) calc(13rem / 16);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.38;
  padding-left: 1.25rem;
}

side-nav > button {
  align-self: center;
  background: transparent;
  border: none;
  grid-column: 3;
  grid-row: 1;
  justify-self: end;
  padding: 0;
}

side-nav > ul {
  grid-column: 1 / span 3;
  grid-row: 2;
  list-style: '';
  margin: 0;
  padding: 0;
}

side-nav li {
  margin: 0 0 0.5rem;
}

@media (min-width: 48rem) {
  side-nav {
    grid-gap: 0;
    padding: 2.5rem  0 0 1.875rem;
  }
  side-nav button {
    display: none;
  }
  side-nav [aria-current],
  side-nav .in-section {
    background: var(--nav-arrow) no-repeat left center / calc(13rem / 16) calc(13rem / 16);
    display: block;
    padding-left: 1.25rem;
  }
  side-nav .short-title {
    display: none;
  }
}

side-nav:defined {
  --background: #fff;
  @media (prefers-color-scheme: dark) {
    --background: var(--brand-blue);
  }
  button[data-action="close"] {
    display: block;
  }
  button[data-action="open"] {
    display: none;
  }
  &[open="false"] {
    padding-block-end: 1.875rem;
    row-gap: 0;
    button[data-action="open"] {
      display: block;
    }
    button[data-action="close"] {
      display: none;
    }
    ul {
      height: 0;
      overflow: hidden;
    }
  }
  @media (min-width: 48rem) {
    &,
    &[open] {
      button[data-action] {
        display: none;
      }
      ul {
        height: auto;
        overflow: visible;
      }
    }
  }
}
