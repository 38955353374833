@property --background-image {
  syntax: "<url>";
  inherits: false;
}

body.template-home {
  main {
    align-self: stretch;
    color: var(--brand-blue);
    font-size-adjust: none;
    position: relative;

    @media (min-width: 48rem) {
      margin-bottom: calc(var(--grid-gap, 0) * -1);


      &::before {
        background: var(--background-image, url("/theme/desktop.jpg")) no-repeat top right / cover var(--brand-blue);
        content: "";
        filter: url("#monochrome");
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
      &[data-dithered]::before {
        filter: none;
      }
    }

    > header {
      font: 400 2.625rem / 1.38 var(--font--sans-serif);
      font-size: clamp(2.25rem, 2.1196rem + 0.6522vw, 2.625rem);

      padding: min(50vh, 27rem) 1rem 3.875rem 0;
      position: relative;
      width: 100%;

      .header-liner {
        max-width: 40rem;
        position: relative;
      }
      .text-wrapper {
        background: #fff;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        display: inline;
        font-weight: 400;
        padding: 0.1em 1.25rem;
      }
      &::before {
        background: var(--background-image, url("/theme/desktop@half.jpg")) no-repeat top center / auto 100% var(--brand-blue);
        content: "";
        filter: url("#monochrome");
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
      &[data-dithered]::before {
        filter: none;
      }
      @media (min-width: 48rem) {
        background: none;
        padding: 9rem 0 2rem;
        &::before {
          content: none;
        }
      }
    }

    article {
      background: #fff;
      font: 400 1.5rem / calc(33.12 / 24) var(--font--sans-serif);
      padding: 1.875rem 1.25rem;
      position: relative;
      width: 100%;
      @media (min-width: 48rem) {
        max-width: min(40rem, calc(100vw - 200px));
      }
       > p:first-child {
         margin-top: 0;
       }
       > p:last-child {
         margin-bottom: 0;
       }
    }

    img, canvas {
      aspect-ratio: 235 / 175;
      border: solid 2px #fff;
    }
    .inset-1 {
      bottom: 3rem;
      position: absolute;
      left: 50%;
      margin-left: -1rem;
      width: min(52vw, 235px);
      z-index: 1;
    }

    .inset-2 {
      height: auto;
      margin: 0 auto 8rem;
      width: min(470px, 100vw);
      > canvas,
      > img {
        width: 100%;
      }
    }

    .inset-3 {
      bottom: 0;
      left: 50%;
      margin-left: calc(-1 * min(52vw, 235px) + 1rem);
      position: absolute;
      width: min(52vw, 235px);
      z-index: 2;
    }
    @media (min-width: 64rem) {
      .inset-wrapper {
        display: flex;
        gap: 0.25rem;
        justify-content: center;
        margin: 3.875rem auto 2.5rem;
        width: 100%;
      }
      .inset-1, .inset-2, .inset-3 {
        bottom: unset;
        height: 175px;
        left: unset;
        margin: 0;
        position: relative;
        top: unset;
        width: 235px;
      }
    }
    @media (min-width: 80rem) {
      .inset-1, .inset-2, .inset-3 {
        position: absolute;
      }

      .inset-1 {
        left: 42.5rem;
        top: 0;
      }

      .inset-2 {
        bottom: 11rem;
        margin-bottom: unset;
        right: 8rem;
      }

      .inset-3 {
        bottom: 1.875rem;
        left: unset;
        margin-left: -2.5rem;
        right: 2.5rem;
      }
    }
  }

}
