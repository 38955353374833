heading-anchors {
  h2, h3, h4, h5, h6 {
    > .ha-placeholder {
      display: none;
    }
  }
}

heading-anchors:defined {
  h2, h3, h4, h5, h6 {
    > .ha-placeholder {
      display: inline-block; // Make Element.offsetTop work as expected in Firefox
      opacity: 0.3;
    }

  }

  h2 + .ha {
    @extend h2; // Try to short circuit some JS
  }
}
