.field {
  input,
  textarea, {
    display: block;
    width: 100%;
  }
  label + br {
    display: none;
  }
}

.field--contact-form-url {
  @extend %sr-only;
}
